<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSn"
                    label="订单号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentSn"
                    label="系统付款单号"
                >
                    <el-input
                        v-model="queryFormModel.paymentSn"
                        placeholder="请输入系统付款单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="externalPaymentSn"
                    label="第三方付款单号"
                >
                    <el-input
                        v-model="queryFormModel.externalPaymentSn"
                        placeholder="请输入第三方付款单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentTime"
                    label="付款时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.paymentTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="orderSn"
                    label="订单号"
                    width="120"
                />
                <el-table-column
                    prop="paymentSn"
                    label="系统付款单号"
                    width="180"
                />
                <el-table-column
                    prop="externalPaymentSn"
                    label="第三方付款单号"
                    width="210"
                />
                <el-table-column
                    prop="paymentTypeName"
                    label="付款类型"
                    min-width="250"
                >
                    <template slot-scope="scope">
                        {{scope.row.paymentTypeName}}({{scope.row.paymentSourceTypeName}}-{{scope.row.paymentSourceDetailTypeName}})
                    </template>
                </el-table-column>
                <el-table-column
                    prop="paymentAmount"
                    label="付款金额"
                    width="90"
                />
                <el-table-column
                    label="付款时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'PaymentOfflineAudit',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orderSn: '',
                paymentSn: '',
                externalPaymentSn: '',
                payer: '', // 付款人
                paymentTime: '',
                paymentStatus: '1',
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.Payment.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
